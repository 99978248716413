import React, { useEffect } from 'react'
import { onBlankLayout } from 'presentation/redux/theme/themeSlice'
import { useDispatch } from 'react-redux'

const RouteApp = ({ component: Component, routeKey, blankLayout, ...props }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const isBlank = blankLayout ? true : false
    dispatch(onBlankLayout(isBlank))
  }, [blankLayout, dispatch])

  return <Component {...props} />
}

export default RouteApp
