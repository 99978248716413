import { getSession } from 'utility/Session'
import { STORE_NOTIFICATION } from './action'

const initialState = {
  unread: getSession('ago-notification') ?? 0,
}

export default function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_NOTIFICATION:
      return {
        ...state,
        unread: action.data,
      }
    default:
      return state
  }
}
