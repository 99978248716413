import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import store from 'presentation/redux/store'
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/ConfigApp'

const themes = {
  light: `${process.env.PUBLIC_URL}/css/theme.css`,
}

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme}>
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  )
}

export default App
