import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AUTHENTICATED_ENTRY } from 'configs/ConfigApp'

const RoutePublic = () => {
  const { account } = useSelector((state) => state.account)

  return account ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />
}

export default RoutePublic
