import React, { lazy, Suspense, memo } from 'react'
import { useSelector } from 'react-redux'
import { ConfigProvider } from 'antd'
import Loading from 'presentation/components/shared/loading'
import { lightTheme, darkTheme } from 'configs/ConfigTheme'
import useBodyClass from 'presentation/hooks/useBodyClass'
import Routes from 'routes'

const AppLayout = lazy(() => import('./layout-app/LayoutApp'))
const AuthLayout = lazy(() => import('./layout-authentication/LayoutAuthentication'))

const Layouts = () => {
  const { account } = useSelector((state) => state.account)

  const blankLayout = useSelector((state) => state.theme.blankLayout)

  const Layout = account && !blankLayout ? AppLayout : AuthLayout

  const direction = useSelector((state) => state.theme.direction)

  const currentTheme = useSelector((state) => state.theme.currentTheme)

  useBodyClass(`dir-${direction}`)

  const themeConfig = currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme }

  return (
    <ConfigProvider theme={themeConfig} direction={direction}>
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  )
}

export default memo(Layouts)
