import { STORE_FORM } from './action'

const initialState = {
  data: {
    dataMaster: [],
    dataPrevious: {},
    dataPayload: {},
  },
}

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_FORM:
      return {
        ...state,
        data: action.data,
      }
    default:
      return state
  }
}
