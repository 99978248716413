import React from 'react'
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom'
import { protectedRoutes, publicRoutes } from 'configs/ConfigRoutes'
import RouteAuth from './RouteAuth'
import RoutePublic from './RoutePublic'
import RouteApp from './RouteApp'

const Routes = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<RouteAuth />}>
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <RouteApp routeKey={route.key} component={route.component} {...route.meta} />
              }
            />
          )
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<RoutePublic />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <RouteApp routeKey={route.key} component={route.component} {...route.meta} />
              }
            />
          )
        })}
      </Route>
    </RouterRoutes>
  )
}

export default Routes
