import { combineReducers } from 'redux'
import accountReducer from './account/reducers'
import themeSlice from './theme/themeSlice'
import formReducer from './form/reducers'
import NotificationReducer from './notification/reducers'
import menuReducer from './menu/reducers'

const rootReducers = combineReducers({
  theme: themeSlice,
  account: accountReducer,
  menu: menuReducer,
  form: formReducer,
  notification: NotificationReducer,
})

export default rootReducers
