import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './utility/i18n'
import 'moment/locale/id'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/reset.css'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
serviceWorker.unregister()
