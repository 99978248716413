import { getSession } from 'utility/Session'
import { STORE_ACCOUNT } from './action'

const initialState = {
  account: getSession('ago-identity'),
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_ACCOUNT:
      return {
        ...state,
        account: action.data,
      }
    default:
      return state
  }
}
