import { getSession } from 'utility/Session'
import { STORE_MENU } from './action'

const initialState = {
  menu: getSession('ago-menu'),
}

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_MENU:
      return {
        ...state,
        menu: action.data,
      }
    default:
      return state
  }
}
