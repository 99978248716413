import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import id from 'language/id.json'
import en from 'language/en.json'
import { getSession } from './Session'

const resources = {
  id,
  en,
}
const lang = getSession('language') ? getSession('language') : 'en'
i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
